// MyCustomIcon.js
import React from "react";

const CustomMicOffIcon = props => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" rx="20" fill="#F75555" />
    <g clip-path="url(#clip0_309_562)">
      <path
        d="M12.6665 12.6666L27.3332 27.3333"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18 18V20C18.0003 20.3953 18.1178 20.7817 18.3376 21.1102C18.5574 21.4388 18.8696 21.6949 19.2349 21.8461C19.6001 21.9974 20.002 22.037 20.3897 21.9599C20.7774 21.8829 21.1336 21.6927 21.4133 21.4134M22 18.2267V14.6667C22.0005 14.1707 21.8167 13.6922 21.4843 13.3242C21.1518 12.9561 20.6945 12.7246 20.201 12.6748C19.7075 12.625 19.2131 12.7602 18.8138 13.0544C18.4145 13.3486 18.1387 13.7806 18.04 14.2667"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M23.3331 23.3C22.6823 23.9642 21.8479 24.4189 20.9369 24.6057C20.0259 24.7925 19.0798 24.7028 18.2201 24.3481C17.3604 23.9935 16.6263 23.3901 16.1119 22.6154C15.5975 21.8407 15.3263 20.9299 15.3331 20V18.6666M24.6665 18.6666V20C24.6662 20.2749 24.6417 20.5493 24.5931 20.82"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20 24.6666V27.3333"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.3335 27.3334H22.6668"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_309_562">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(12 12)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default CustomMicOffIcon;
