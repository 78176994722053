import { Flex, Loading, Text } from "@100mslive/roomkit-react";

const FullPageProgress = () => (
  <Flex justify="center" align="center" direction="column" css={{ size: "100%" }}>
    <Loading size={100} />
    <Text color="white" variant="sm" css={{ px: "10%", textAlign: "center" }}>
      You can safely resresh the page if connecting is taking longer than expected      
    </Text>
  </Flex>
);

export default FullPageProgress;
