import React from "react";
import { Button, Input, styled, Flex } from "@100mslive/roomkit-react";
import { isStreamingKit } from "../../common/utils";
import { ScreeningContext } from '../../context/ScreeningContext';
import { useAVToggle } from "@100mslive/react-sdk";

const PreviewName = ({ name, onChange, onJoin, enableJoin }) => {
  const { isLocalVideoEnabled, isLocalAudioEnabled } = useAVToggle();
  const { screeningData } = React.useContext(ScreeningContext);
  const formSubmit = e => {
    e.preventDefault();
  };
  return (
    <Form css={{ width: "auto", position: "relative" }} onSubmit={formSubmit}>
      <Flex direction="column">
        {enableJoin && isLocalAudioEnabled && isLocalVideoEnabled ?
          <Button type="submit" disabled={!enableJoin} onClick={onJoin} css={{ w: "300px", h: "48px", fontSize: "$sm", fontWeight: "400", fontFamily: 'Nunito' }}>
            {screeningData.questionIndex == 0 ? "Start interview now" : 'Next question'}
          </Button>
          :
          <Button disabled={true} css={{ w: "300px", h: "48px", fontSize: "$sm", fontWeight: "$semiBold", wordBreak: "break-word", overflowWrap: "break-word", bg: "#5186ED!important", opacity: "0.5", fontWeight: "400", fontFamily: 'Nunito', }}>
            Start interview now
          </Button>
        }
        <a target="_blank" href={screeningData.screeningUrl} style={{
          border: '2px solid #A6A8AC',
          borderRadius: '0.5rem', 
          width: '100%',
          flexDirection: "column",
          height: 'auto',
          textDecoration: 'none',
          color: '#A6A8AC',
          height: "48px",
          textAlign: "center",
          alignContent: "center",
          fontSize: "0.875rem",
          marginTop: "24px"
        }}>
          Schedule Interview
        </a>
      </Flex>
    </Form>
  );
};

const Form = styled("form", {
  width: "100%",
  display: "flex",
  alignItems: "center",
});

export default PreviewName;