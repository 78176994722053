import React, { useCallback, useState, useEffect } from "react";
import { CheckIcon } from "@100mslive/react-icons";
import { flexCenter, styled, Flex, Label, Checkbox, Text, Button } from "@100mslive/roomkit-react";
import PreviewJoin from "./PreviewJoin";
import PreviewName from "./PreviewName";
import { useAVToggle, usePreviewJoin } from "@100mslive/react-sdk";
import { UserPreferencesKeys, defaultPreviewPreference, useUserPreferences } from "../hooks/useUserPreferences";
import { ScreeningContext } from "../../context/ScreeningContext";
import Check from "../../icons/Check";
import CrossMark from "../../icons/CrossMark";
import { HMSVirtualBackgroundPlugin } from '@100mslive/hms-virtual-background';
import { useHMSStore, useHMSActions, selectIsLocalVideoPluginPresent } from '@100mslive/react-sdk';

const PreviewContainer = ({
  token,
  onJoin,
  env,
  skipPreview,
  initialName,
  asRole,
}) => {
  const [previewPreference, setPreviewPreference] = useUserPreferences(
    UserPreferencesKeys.PREVIEW,
    defaultPreviewPreference
  );
  const { screeningData } = React.useContext(ScreeningContext);
  const [name, setName] = useState(screeningData.developerName);
  const { isLocalAudioEnabled, isLocalVideoEnabled } = useAVToggle();
  const [previewError, setPreviewError] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const virtualBackground = new HMSVirtualBackgroundPlugin('blur');
  const { enableJoin, preview, join } = usePreviewJoin({
    name,
    token,
    initEndpoint: env ? `https://${env}-init.100ms.live/init` : undefined,
    initialSettings: {
      isAudioMuted: skipPreview || previewPreference.isAudioMuted,
      isVideoMuted: skipPreview || previewPreference.isVideoMuted,
      speakerAutoSelectionBlacklist: ["Yeti Stereo Microphone"],
    },
    captureNetworkQualityInPreview: true,
    handleError: (_, method) => {
      if (method === "preview") {
        setPreviewError(true);
      }
    },
    asRole,
  });

  const savePreferenceAndJoin = useCallback(() => {
    setPreviewPreference({
      name,
      isAudioMuted: !isLocalAudioEnabled,
      isVideoMuted: !isLocalVideoEnabled,
    });
    join();
    onJoin && onJoin();
  }, [
    join,
    isLocalAudioEnabled,
    isLocalVideoEnabled,
    name,
    setPreviewPreference,
    onJoin,
  ]);

  const handleCheckboxChange = () => {
    setIsCheckboxChecked(!isCheckboxChecked);
  };

  const isVirtualBackgroundEnabled = useHMSStore(
    selectIsLocalVideoPluginPresent(virtualBackground.getName())
  );
  const hmsActions = useHMSActions();

  useEffect(() => {
    const applyVirtualBackground = async () => {
      try {
        if (!isVirtualBackgroundEnabled) {
          // Recommended value
          const pluginFrameRate = 15;
          await hmsActions.addPluginToVideoTrack(virtualBackground, pluginFrameRate);
        }
      } catch (err) {
        console.log('virtual background failure - ', isVirtualBackgroundEnabled, err);
      }
    };

    applyVirtualBackground();
  }, [hmsActions, isVirtualBackgroundEnabled, virtualBackground]);

  return (
    <Flex
      css={{
        position: "relative",
        width: "100%",
        flexDirection: "column",
        px: "50px",
        bg: "white",
        "@sm":{
          height: "fit-content",
          padding: "50px"
        }
      }}>
      <Flex
        justify="center"
        align="center"
        css={{
          position: "relative",
          width: "100%",
          height: "11rem",
          flexDirection: "column"
        }}>
        <Flex
          css={{
            fontSize: "$h5",
            fontWeight: "700",
            fontFamily: "Nunito",
            "@sm":{
              fontSize: "$h6",
            }
          }}>
          You’re about to start
        </Flex>
        <Flex

          css={{
            width: "50%",
            color: '#545964',
            fontSize: '1rem',
            textAlign: "center",
            mt: '0.75rem',
            fontFamily: 'Nunito',
            fontWeight: '600',
            "@sm": { width: "100%", mb: '1.25rem', fontSize: "$xs" },
          }}>
          The next step is to complete a quick interview with our AI assistant. Please check these recommendations before your interview: take time to answer every question, and remember that the interview could last around 20 minutes in total.
        </Flex>
      </Flex>
      <Flex
        css={{
          position: "relative",
          width: "100%",
          flexDirection: "row",
          gap: "35px",
          "@sm":{
            flexDirection: "column",
            mt: "1rem",
          }
        }}>
        <PreviewJoin
          initialName={initialName}
          skipPreview={skipPreview}
          env={env}
          onJoin={onJoin}
          token={token}
          asRole={asRole}
        />
        <Flex css={{
          width: "50%",
          flexDirection: "column",
          "@sm": {
            width: "100%"
          }
        }}>
          <Flex css={{
            width: "100%",
            direction: "row",
            gap: "24px",
            "@sm": {
              flexDirection: "column",
            }
          }}>
            <Flex css={{
              width: "50%",
              direction: "row",
              "@sm": {
                width: "100%",
              }
            }}>
              <Flex css={{
                border: '2px solid #A3E1AD', // Color de borde simbiosis-greenscreening
                minHeight: '13rem', // Aproximadamente equivalente a h-52
                borderRadius: '0.5rem', // Aproximadamente equivalente a rounded-lg
                width: '100%',
                flexDirection: "column",
                height: 'auto',
              }}>
                <Flex
                  justify="center"
                  css={{
                    backgroundColor: '#A3E1AD', // Color de fondo simbiosis-greenscreening
                    textAlign: 'center',
                    padding: '0.5rem',
                    fontFamily: 'Nunito',
                    fontWeight: '600',
                    "@sm":{
                      fontSize: "$sm"
                    }
                  }}>
                  Recommendations
                </Flex>
                <Flex
                  css={{
                    display: 'flex',
                    flexDirection: 'column',
                    fontSize: '0.875rem',
                    padding: '16px',
                    fontFamily: 'Nunito',
                    fontWeight: '600',
                  }}
                >
                  <Flex align="center"
                    css={{
                      mb: "16px",
                    }}>
                    <Flex css={{
                      width: "16px",
                      height: "16px",
                      mr: "16px",
                    }}>
                      <Check />
                    </Flex>
                    <Text css={{ display: 'flex', fontSize: "0.875rem", color: "#545964", fontFamily: 'Nunito',
                    fontWeight: '600',}}>
                      Activate your Camera and Microphone permissions before start
                    </Text>
                  </Flex>
                  <Flex align="center"
                    css={{
                      mb: "16px",
                    }}>
                    <Flex css={{
                      width: "16px",
                      height: "16px",
                      mr: "16px",
                    }}>
                      <Check />
                    </Flex>
                    <Text css={{ display: 'flex', fontSize: "0.875rem", color: "#545964", fontFamily: 'Nunito',
                    fontWeight: '600',}}>
                      Check your internet connection
                    </Text>
                  </Flex>
                  <Flex align="center">
                    <Flex css={{
                      width: "16px",
                      height: "16px",
                      mr: "16px",
                    }}>
                      <Check />
                    </Flex>
                    <Text css={{ display: 'flex', fontSize: "0.875rem", color: "#545964", fontFamily: 'Nunito',
                    fontWeight: '600',}}>
                      Make sure you are in a bright and quiet space
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
            <Flex css={{
              width: "50%",
              direction: "row",
              "@sm": {
                width: "100%",
              }
            }}>
              <Flex css={{
                border: '2px solid #FDB7B7',
                minHeight: '13rem',
                borderRadius: '0.5rem',
                width: '100%',
                flexDirection: "column",
                height: 'auto',
              }}>
                <Flex
                  justify="center"
                  css={{
                    backgroundColor: '#FDB7B7',
                    textAlign: 'center',
                    padding: '0.5rem',
                    fontFamily: 'Nunito',
                    fontWeight: '600',
                    "@sm":{
                      fontSize: "$sm"
                    }
                  }}>
                  Avoid this
                </Flex>
                <Flex
                  css={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '16px',
                    fontFamily: 'Nunito',
                    fontWeight: '600',
                  }}
                >
                  <Flex align="center"
                    css={{
                      mb: "16px",
                    }}>
                    <Flex css={{
                      width: "16px",
                      height: "16px",
                      mr: "16px",
                    }}>
                      <CrossMark />
                    </Flex>
                    <Text css={{ display: 'flex', fontSize: "0.875rem", color: "#545964", fontFamily: 'Nunito',
                    fontWeight: '600', }}>
                      Changing your browser tab. We want you to focus on the interview
                    </Text>
                  </Flex>
                  <Flex align="center"
                    css={{
                      mb: "16px",
                    }}>
                    <Flex css={{
                      width: "16px",
                      height: "16px",
                      mr: "16px",
                    }}>
                      <CrossMark />
                    </Flex>
                    <Text css={{ display: 'flex', fontSize: "0.875rem", color: "#545964", fontFamily: 'Nunito',
                    fontWeight: '600', }}>
                      Consulting AI tools. We want to evaluate your knowledge and skills
                    </Text>
                  </Flex>
                  <Flex align="center">
                    <Flex css={{
                      width: "16px",
                      height: "16px",
                      mr: "16px",
                    }}>
                      <CrossMark />
                    </Flex>
                    <Text css={{ display: 'flex', fontSize: "0.875rem", color: "#545964", fontFamily: 'Nunito',
                    fontWeight: '600', }}>
                      Taking this call in noisy and public spaces.
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            align="center"
            justify="center"
            css={{
              flexDirection: "column",
            }}>
            <Flex
              align="center"
              css={{
                flexDirection: "row",
                py: "24px",
              }}>
              <input type="checkbox" style={{
                width: "24px",
                height: "24px",
                accentColor: "#5186ED",
              }}
                onChange={handleCheckboxChange}
              />
              <Label htmlFor="test" css={{
                display: 'flex',
                color: '#64748B',
                fontSize: '0.875rem',
                ml: "8px",
                fontFamily: 'Nunito',
                fontWeight: '600',
              }}>
                I meet the requirements mentioned to start this interview.
              </Label>
            </Flex>
            <PreviewName
              name={name}
              onJoin={savePreferenceAndJoin}
              enableJoin={enableJoin && isCheckboxChecked}
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex >
  );
};

const Container = styled("div", {
  width: "100%",
  heigth: "80%",
  flexDirection: "row",
});

export default PreviewContainer;