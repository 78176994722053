import React from "react";
import { Flex, VerticalDivider } from "@100mslive/roomkit-react";
import { SpeakerTag } from "./HeaderComponents";
import { StreamActions } from "./StreamActions";
import { ScreeningContext } from '../../context/ScreeningContext';
import { Text } from "@100mslive/roomkit-react";

export const ConferencingHeader = ({ isPreview }) => {
  const { screeningData } = React.useContext(ScreeningContext);
  return (
    <Flex
      justify="between"
      align="center"
      css={{ position: "relative", height: "100%", }}
    >

      {!isPreview ?
        <Flex
          align="center"
          css={{
            position: "absolute",
            right: "$10",
            gap: "$6",
          }}
        >
          <StreamActions />
        </Flex>
        : null}
    </Flex>
  );
};
