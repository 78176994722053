// MyCustomIcon.js
import React from "react";

const CustomMicOnIcon = props => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="white" />
    <path
      d="M20 12.6667C19.4696 12.6667 18.9609 12.8775 18.5858 13.2525C18.2107 13.6276 18 14.1363 18 14.6667V20.0001C18 20.5305 18.2107 21.0392 18.5858 21.4143C18.9609 21.7894 19.4696 22.0001 20 22.0001C20.5304 22.0001 21.0391 21.7894 21.4142 21.4143C21.7893 21.0392 22 20.5305 22 20.0001V14.6667C22 14.1363 21.7893 13.6276 21.4142 13.2525C21.0391 12.8775 20.5304 12.6667 20 12.6667Z"
      stroke="white"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M24.6667 18.6667V20.0001C24.6667 21.2378 24.175 22.4247 23.2998 23.2999C22.4247 24.1751 21.2377 24.6667 20 24.6667C18.7623 24.6667 17.5753 24.1751 16.7002 23.2999C15.825 22.4247 15.3333 21.2378 15.3333 20.0001V18.6667"
      stroke="white"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20 24.6667V27.3334"
      stroke="white"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17.3333 27.3333H22.6667"
      stroke="white"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default CustomMicOnIcon;
