// MyCustomIcon.js
import React from "react";

const CustomCameraOffIcon = props => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" rx="20" fill="#F75555" />
    <g clip-path="url(#clip0_309_568)">
      <path
        d="M22.6665 22.6667V23.3334C22.6665 23.687 22.526 24.0261 22.276 24.2762C22.0259 24.5262 21.6868 24.6667 21.3332 24.6667H13.9998C13.6462 24.6667 13.3071 24.5262 13.057 24.2762C12.807 24.0261 12.6665 23.687 12.6665 23.3334V16.6667C12.6665 16.3131 12.807 15.9739 13.057 15.7239C13.3071 15.4738 13.6462 15.3334 13.9998 15.3334H15.3332M19.1065 15.3334H21.3332C21.6868 15.3334 22.0259 15.4738 22.276 15.7239C22.526 15.9739 22.6665 16.3131 22.6665 16.6667V18.8934L23.3332 19.56L27.3332 16.6667V23.3334"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.6665 12.6666L27.3332 27.3333"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_309_568">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(12 12)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default CustomCameraOffIcon;
