// MyCustomIcon.js
import React from "react";

const Check = props => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_354_278)">
      <path
        d="M8 14.6666C11.6819 14.6666 14.6667 11.6818 14.6667 7.99992C14.6667 4.31802 11.6819 1.33325 8 1.33325C4.3181 1.33325 1.33334 4.31802 1.33334 7.99992C1.33334 11.6818 4.3181 14.6666 8 14.6666Z"
        stroke="#2B9D55"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.9984 6.04541L6.99836 10.0454L5.18018 8.22723"
        stroke="#2B9D55"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_354_278">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Check;
