// MyCustomIcon.js
import React from "react";

const CrossMark = props => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_313_314)">
      <path
        d="M7.99992 14.6666C11.6818 14.6666 14.6666 11.6818 14.6666 7.99992C14.6666 4.31802 11.6818 1.33325 7.99992 1.33325C4.31802 1.33325 1.33325 4.31802 1.33325 7.99992C1.33325 11.6818 4.31802 14.6666 7.99992 14.6666Z"
        stroke="#D85E5F"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10 6L6 10"
        stroke="#D85E5F"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6 6L10 10"
        stroke="#D85E5F"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_313_314">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default CrossMark;
