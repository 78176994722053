import { Fragment, useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useMedia } from "react-use";
import {
  selectAppData,
  selectIsConnectedToRoom,
  selectPermissions,
  useHMSActions,
  useHMSStore,
  useRecordingStreaming,
} from "@100mslive/react-sdk";
import { EndStreamIcon, RecordIcon } from "@100mslive/react-icons";
import {
  Box,
  Button,
  config as cssConfig,
  Flex,
  Loading,
  Popover,
  Text,
  Tooltip,
} from "@100mslive/roomkit-react";
import GoLiveButton from "../GoLiveButton";
import { ResolutionInput } from "../Streaming/ResolutionInput";
import { getResolution } from "../Streaming/RTMPStreaming";
import { ToastManager } from "../Toast/ToastManager";
import { AdditionalRoomState, getRecordingText } from "./AdditionalRoomState";
import { useSidepaneToggle } from "../AppData/useSidepane";
import { useSetAppDataByKey } from "../AppData/useUISettings";
import { getDefaultMeetingUrl } from "../../common/utils";
import {
  APP_DATA,
  RTMP_RECORD_DEFAULT_RESOLUTION,
  SIDE_PANE_OPTIONS,
} from "../../common/constants";
import { ScreeningContext } from '../../context/ScreeningContext';

export const LiveStatus = () => {
  const { isHLSRunning, isRTMPRunning } = useRecordingStreaming();
  if (!isHLSRunning && !isRTMPRunning) {
    return null;
  }
  return (
    <Flex align="center">
      <Box css={{ w: "$4", h: "$4", r: "$round", bg: "$error", mr: "$2" }} />
      <Text>
        Live
        <Text as="span" css={{ "@md": { display: "none" } }}>
          &nbsp;with {isHLSRunning ? "HLS" : "RTMP"}
        </Text>
      </Text>
    </Flex>
  );
};

export const RecordingStatus = () => {
  const {
    isBrowserRecordingOn,
    isServerRecordingOn,
    isHLSRecordingOn,
    isRecordingOn,
  } = useRecordingStreaming();
  const permissions = useHMSStore(selectPermissions);

  if (
    !isRecordingOn ||
    // if only browser recording is enabled, stop recording is shown
    // so no need to show this as it duplicates
    [
      permissions?.browserRecording,
      !isServerRecordingOn,
      !isHLSRecordingOn,
      isBrowserRecordingOn,
    ].every(value => !!value)
  ) {
    return null;
  }
  return (
    <Tooltip
      title={getRecordingText({
        isBrowserRecordingOn,
        isServerRecordingOn,
        isHLSRecordingOn,
      })}
    >
      <Box
        css={{
          color: "$error",
        }}
      >
        <RecordIcon width={24} height={24} />
      </Box>
    </Tooltip>
  );
};

const EndStream = () => {
  const { isStreamingOn } = useRecordingStreaming();
  const toggleStreaming = useSidepaneToggle(SIDE_PANE_OPTIONS.STREAMING);
  if (!isStreamingOn) {
    return null;
  }
  return (
    <Button
      data-testid="end_stream"
      variant="standard"
      outlined
      icon
      onClick={() => {
        toggleStreaming();
      }}
    >
      <EndStreamIcon />
      End Stream
    </Button>
  );
};

const StartRecording = () => {
  const permissions = useHMSStore(selectPermissions);
  const recordingUrl = useHMSStore(selectAppData(APP_DATA.recordingUrl));
  const [resolution, setResolution] = useState(RTMP_RECORD_DEFAULT_RESOLUTION);
  const [open, setOpen] = useState(false);
  const [recordingStarted, setRecordingState] = useSetAppDataByKey(
    APP_DATA.recordingStarted
  );
  const { isBrowserRecordingOn, isStreamingOn, isHLSRunning } =
    useRecordingStreaming();
  const hmsActions = useHMSActions();
  const { screeningData, setScreeningData } = React.useContext(ScreeningContext);  
  const{ roomId, role } = useParams();

  const wasCalled = useRef(false);
  useEffect(() => {      
    async function autoRecord () {
      try {        
        await hmsActions.startRTMPOrRecording({
          meetingURL: `https://score-prescreening.app.100ms.live/preview/${roomId}/${role}?skip_preview=true&candidate_id=${screeningData.candidateId}&question_id=${screeningData.questionArray[screeningData.questionIndex].question_id}`,//recordingUrl,
          resolution: {"width": 1920, "height": 1080},
          record: true,
        });
        setRecordingState(true);        
      } catch (error) {
        if (error.message.includes("stream already running")) {
          ToastManager.addToast({
            title: "Recording already running",
            variant: "error",
          });
        } else {
          ToastManager.addToast({
            title: error.message,
            variant: "error",
          });
          setRecordingState(false);
        }        
      }
    }
    if(wasCalled.current)
    {
      return;
    }
    else
    {      
      wasCalled.current = true;
    }
    if(!recordingStarted && !isBrowserRecordingOn)
    {
      autoRecord();
    }    
  }, []);
  useEffect(() => {      
    if(isBrowserRecordingOn)
    {
      setScreeningData({
        ...screeningData,
      });
    }
  }, [isBrowserRecordingOn]);

  if (!permissions?.browserRecording || isHLSRunning) {
    return null;
  }
  if (isBrowserRecordingOn) {
    return (
      <Popover.Root>
        <Popover.Trigger asChild>
          <Button
            variant="danger"
            data-testid="stop_recording"
            icon
            outlined
          >
            <Box
              css={{
                color: "$error",
              }}
            >
              <RecordIcon />
            </Box>
            <Text
              as="span"
              css={{ "@md": { display: "none" }, color: "currentColor" }}
            >
              Recording
            </Text>
          </Button>
        </Popover.Trigger>
      </Popover.Root>
    );
  }
  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <Button
          data-testid="start_recording"
          variant="standard"
          icon
        >
          <Loading size={24} color="currentColor" />
          <Text
            as="span"
            css={{ "@md": { display: "none" }, color: "currentColor" }}
          >
            Recording
          </Text>
        </Button>
      </Popover.Trigger>      
    </Popover.Root>
  );
};

export const StreamActions = () => {
  const isConnected = useHMSStore(selectIsConnectedToRoom);
  const permissions = useHMSStore(selectPermissions);
  const isMobile = useMedia(cssConfig.media.md);
  return (
    <Flex align="center" css={{ gap: "$4" }}>
      <AdditionalRoomState />
      <Flex align="center" css={{ gap: "$4", "@md": { display: "none" } }}>
        <LiveStatus />
        <RecordingStatus />
      </Flex>
      {isConnected ?
        <StartRecording />
      : null}            
    </Flex>
  );
};
