import { Fragment, useState } from "react";
import { useParams } from "react-router-dom";
import {
  selectIsConnectedToRoom,
  selectPermissions,
  useHMSActions,
  useHMSStore,
  useAVToggle,
  selectLocalPeerID,
} from "@100mslive/react-sdk";
import {
  AlertTriangleIcon,
  ExitIcon,
  HangUpIcon,
  VerticalMenuIcon,
} from "@100mslive/react-icons";
import {
  Box,
  Button,
  Dialog,
  Dropdown,
  Flex,
  IconButton,
  styled,
  Text,
  Tooltip,
} from "@100mslive/roomkit-react";
import { ToastManager } from "./Toast/ToastManager";
import {
  DialogCheckbox,
  DialogContent,
  DialogRow,
} from "../primitives/DialogContent";
import { useDropdownList } from "./hooks/useDropdownList";
import { useNavigation } from "./hooks/useNavigation";
import { ScreeningContext } from '../context/ScreeningContext';
import { isStreamingKit } from "../common/utils";
import { useSetAppDataByKey } from "./AppData/useUISettings";
import {
  APP_DATA,
  RTMP_RECORD_DEFAULT_RESOLUTION,
} from "../common/constants";

export const LeaveRoom = ({ isBrowserRecordingOn }) => {
  const { roomId, role } = useParams();
  const localPeerID = useHMSStore(selectLocalPeerID);
  const navigate = useNavigation();
  const params = useParams();
  const [open, setOpen] = useState(false);
  const [showEndRoomModal, setShowEndRoomModal] = useState(false);
  const [lockRoom, setLockRoom] = useState(true);
  const isConnected = useHMSStore(selectIsConnectedToRoom);
  const permissions = useHMSStore(selectPermissions);
  const hmsActions = useHMSActions();
  const { screeningData, setScreeningData } = React.useContext(ScreeningContext);
  const { isLocalAudioEnabled, isLocalVideoEnabled } = useAVToggle();
  const [recordingStarted, setRecordingState] = useSetAppDataByKey(
    APP_DATA.recordingStarted
  ); 
  useDropdownList({ open, name: "LeaveRoom" });

  async function resetRecording () {
    try {
      setRecordingState(false);
      await hmsActions.stopRTMPAndRecording();            
      await hmsActions.startRTMPOrRecording({
        meetingURL: `https://score-prescreening.app.100ms.live/preview/${roomId}/${role}?skip_preview=true&candidate_id=${screeningData.candidateId}&question_id=${screeningData.questionArray[screeningData.questionIndex + 1].question_id}`,//recordingUrl,
        resolution: {"width": 1920, "height": 1080},
        record: true,
      });            
      setRecordingState(true);
      setButtonDisabled(false);        
    } catch (error) {
      if (error.message.includes("stream already running")) {
        ToastManager.addToast({
          title: "Recording already running",
          variant: "error",
        });
      } else {
        ToastManager.addToast({
          title: error.message,
          variant: "error",
        });
      }
      setRecordingState(false);
    }
  }

  async function endRecording() {
    setRecordingState(false);
    await hmsActions.stopRTMPAndRecording(); 
    hmsActions.endRoom(lockRoom, "End Room");
    redirectToLeavePage();
  };

  const redirectToLeavePage = () => {
    if (role) {
      navigate("/leave/" + roomId + "/" + role);
    } else {
      navigate("/leave/" + roomId);
    }
    ToastManager.clearAllToast();
  };

  const notifyAnswer = ()=> {
    window.parent.postMessage({
      id: "screening-data-answers",
      questionsAnswered: (screeningData.questionIndex + 1),
      questionsRemaining:  (screeningData.questionArray.length - (screeningData.questionIndex + 1)),
      roomId: roomId,
      peerId: localPeerID,
      questionId: screeningData.questionArray[screeningData.questionIndex].question_id,
    }, "*"); 
  }

  const [isButtonDisabled, setButtonDisabled] = useState(false);

  const continueRoom = () => {  
    setButtonDisabled(true);
    if(screeningData.questionIndex + 1 >= screeningData.questionArray.length) {
      notifyAnswer();
      setScreeningData({
        ...screeningData,        
        loadingQuestion: true,
      });
      endRecording();
      window.parent.postMessage({ id: "reload-page" }, "*");
    } else {
      notifyAnswer();      
      resetRecording();
      setScreeningData({
        ...screeningData,
        questionIndex: screeningData.questionIndex + 1,
        currentQuestion: screeningData.currentQuestion + 1,
        loadingQuestion: true,
      });
    }    
  };

  const isStreamKit = isStreamingKit();
  if (!permissions || !isConnected) {
    return null;
  }

  return (
    <Fragment>
      { isLocalAudioEnabled ?
        <Button
          onClick={continueRoom}
          key="LeaveRoom"
          data-testid="leave_room_btn"
          disabled={!isBrowserRecordingOn || isButtonDisabled}
          css={{
            fontSize: "$sm",
            fontWeight: "$semiBold",
            w: "100%",    
            r: "$1",    
            "@md": {width: "100%"}                              
          }}
        >
          {screeningData.questionIndex + 1 >= screeningData.questionArray.length ? "Finish process" : "Next question"}
        </Button>
        :
        <Button disabled={true}
          css={{
            wordBreak: "break-word",
            fontSize: "$sm",
            fontWeight: "$semiBold",
            w: "35%",    
            r: "$1",    
            "@md": {width: "100%"}                              
          }}
        >
          Enable your microphone to continue         
        </Button>
      }
    </Fragment>
  );
};

const LeaveIconButton = styled(IconButton, {
  color: "$on_primary_high",
  h: "$14",
  px: "$8",
  r: "$1",
  bg: "$alert_error_default",
  "&:not([disabled]):hover": {
    bg: "$alert_error_bright",
  },
  "&:not([disabled]):active": {
    bg: "$alert_error_bright",
  },
  "@md": {
    px: "$4",
    mx: 0,
  },
});

const MenuTriggerButton = styled(LeaveIconButton, {
  borderLeft: "1px solid $alert_error_dim",
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  px: "$3",
  "@md": {
    px: "$2",
  },
});
