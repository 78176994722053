// MyCustomIcon.js
import React from "react";

const CustomCameraOnIcon = props => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="white" />
    <path
      d="M27.3334 16.6666L22.6667 20L27.3334 23.3333V16.6666Z"
      stroke="white"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21.3334 15.3334H14.0001C13.2637 15.3334 12.6667 15.9303 12.6667 16.6667V23.3334C12.6667 24.0698 13.2637 24.6667 14.0001 24.6667H21.3334C22.0698 24.6667 22.6667 24.0698 22.6667 23.3334V16.6667C22.6667 15.9303 22.0698 15.3334 21.3334 15.3334Z"
      stroke="white"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default CustomCameraOnIcon;
