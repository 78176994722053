import React, { Fragment, useEffect } from "react";
import {
  selectIsConnectedToRoom,
  selectLocalVideoTrackID,
  selectVideoTrackByID,
  useAVToggle,
  useHMSActions,
  useHMSStore,
} from "@100mslive/react-sdk";
import {
  CameraFlipIcon,
  MicOffIcon,
  MicOnIcon,
  VideoOffIcon,
  VideoOnIcon,
} from "@100mslive/react-icons";
import CustomMicOnIcon from '../../src/icons/CustomMicOnIcon';
import CustomMicOffIcon from '../../src/icons/CustomMicOffIcon';
import CustomCameraOnIcon from '../../src/icons/CustomCameraOnIcon';
import CustomCameraOffIcon from '../../src/icons/CustomCameraOffIcon';
import { Tooltip } from "@100mslive/roomkit-react";
import { ToastManager } from "./Toast/ToastManager";
import IconButton from "../IconButton";
import { isMacOS } from "../common/constants";

export const AudioVideoToggle = () => {
  const { isLocalVideoEnabled, isLocalAudioEnabled, toggleAudio, toggleVideo } =
    useAVToggle();
  const actions = useHMSActions();
  const videoTracKId = useHMSStore(selectLocalVideoTrackID);
  const localVideoTrack = useHMSStore(selectVideoTrackByID(videoTracKId));
  const isConnectedToRoom = useHMSStore(selectIsConnectedToRoom);
  
  useEffect(() => {
    const handleAudioToggle = () => {
      window.parent.postMessage(
        { id: "microphoneStatus", isMicrophoneOn: isLocalAudioEnabled},
        "*"
      );
    };

    handleAudioToggle();
  }, [isLocalAudioEnabled]);

  return (
    <Fragment>
      {toggleAudio ? (
          <IconButton
            css={{
              width: "50px",
              height: "50px",
              "&:hover": {
                backgroundColor:"$transparentBg !important"
              },
            }}
            onClick={toggleAudio}
            key="toggleAudio"
            data-testid="audio_btn"
          >
            {!isLocalAudioEnabled ? (
              <CustomMicOffIcon data-testid="audio_off_btn" />
            ) : (
              <CustomMicOnIcon data-testid="audio_on_btn" />
            )}
          </IconButton>
      ) : null}
      {toggleVideo ? (
          <IconButton
            css={{
              width: "50px",
              height: "50px",
              backgroundColor:"$transparentBg !important",
              "&:hover": {
                backgroundColor:"$transparentBg !important"
              },
            }}
            key="toggleVideo"
            active={isLocalVideoEnabled}
            onClick={toggleVideo}
            data-testid="video_btn"
          >
            {!isLocalVideoEnabled ? (
              <CustomCameraOffIcon data-testid="video_off_btn" />
            ) : (
              <CustomCameraOnIcon data-testid="video_on_btn" />
            )}
          </IconButton>
      ) : null}
      {localVideoTrack?.facingMode && isConnectedToRoom ? (
        <Tooltip title="Switch Camera" key="switchCamera">
          <IconButton
            onClick={async () => {
              try {
                await actions.switchCamera();
              } catch (e) {
                ToastManager.addToast({
                  title: `Error while flipping camera ${e.message || ""}`,
                  variant: "error",
                });
              }
            }}
          >
            <CameraFlipIcon />
          </IconButton>
        </Tooltip>
      ) : null}
    </Fragment>
  );
};
